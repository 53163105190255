import { NETWORK_INFO, TOKEN_INFO } from "../../constants/wallet";
import { ApiServices } from "../../pages/Exchange/services/api.services";
import { toFixedDeposit } from "../../services/HelperServices";
import { web3Services } from "../../services/web3.service";
import store from "../_store/configureStore";

/**
 * Deposit action
 * @param {*} currentProvider
 * @param {*} walletAddress
 * @param {*} amount
 * @param {*} coin
 * @param {*} chainId
 * @returns
 */
export const depositToContract = async (amount, fee) => {
  try {
    const chainId = store.getState()?.user?.chainId;
    const network = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    );
    const networkName = network?.label;

    const allwance = await checkAllowance();
    if (networkName === "Ethereum") {
      console.log("Ethereum network");
      let calculatedAllowance = await toFixedDeposit(allwance, networkName);
      console.log(">>>>!", calculatedAllowance, amount);
      if (
        Number(calculatedAllowance) < Number(amount) &&
        Number(calculatedAllowance) !== 0
      ) {
        console.log("Case: not zero, but less than DA");
        //Reset allowance to 0
        const allowance = await sendAllowance(Number(0));
        //Set desired allowance
        if (allowance) {
          const sendAllowance1 = await sendAllowance(amount);

          if (sendAllowance1) {
            const checkallowance = await checkAllowance();
            let calculatedAllowance1 = await toFixedDeposit(
              checkallowance,
              networkName
            );
            console.log(">>>>checkallowance1", calculatedAllowance1);
            if (Number(calculatedAllowance1) < Number(amount)) {
              let error = {
                message: "Allowance should be greater than deposit amount !",
              };
              throw error;
            }
          }
        }
      } else if (Number(calculatedAllowance) === 0) {
        console.log("case: allowance is zero");
        const allowance = await sendAllowance(amount);
        if (allowance) {
          const checkallowance = await checkAllowance();
          let calculatedAllowance1 = await toFixedDeposit(
            checkallowance,
            networkName
          );
          // console.log(">>>>checkallowance1", calculatedAllowance1);
          if (Number(calculatedAllowance1) < amount) {
            let error = {
              message: "Allowance should be greater than deposit amount !",
            };
            throw error;
          }
        }
      } else {
        console.log("case: enough allowance");
      }
    } else {
      const allowance = await sendAllowance(amount);

      if (allowance) {
        const checkallowance = await checkAllowance();
        console.log(">>>>>>>>>allowance", checkallowance);
        let calculatedAllowance = await toFixedDeposit(
          checkallowance,
          networkName
        );
        console.log(">>>>checkallowance", checkallowance, calculatedAllowance);
        if (Number(calculatedAllowance) < amount) {
          let error = {
            message: "Allowance should be greater than deposit amount !",
          };
          throw error;
        }
      }
    }

    console.log("depositing now", amount, fee);
    const res = await web3Services.deposit(amount, fee);
    if (res) {
      // setIsLoader(false);
      // handleClose();
      // handleCloseMain();
      // setAmount("");
      return true;
    }
  } catch (error) {
    console.log("errorerror", error);
    // setIsLoader(false);

    throw error;
  }
};

export const getp2pFristTokenBalances = async (
  walletAddress,
  chainId,
  currentProvider,
  platform,
  tokenName
) => {
  // console.log(
  //   "balance-web3-balance =>>>>>>>>>>>>>>>>>>>>>>>>>>>",
  //   walletAddress,
  //   chainId,
  //   currentProvider,
  //   platform,
  //   tokenName
  // );

  try {
    const tokenAddress =
      platform && platform === "P2P"
        ? TOKEN_INFO.find((doc) => doc.name === tokenName)?.token_address
        : NETWORK_INFO.find((doc) => doc.chain_id === Number(chainId))
            ?.tokenAddress;

    console.log("tokenAddress", tokenAddress);

    const response = await web3Services.getp2pFristTokenBalances(
      walletAddress,
      currentProvider,
      String(tokenAddress),
      tokenName
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getp2pSecondTokenBalances = async (
  walletAddress,
  chainId,
  currentProvider,
  platform,
  tokenName
) => {
  try {
    const tokenAddress =
      platform && platform === "P2P"
        ? TOKEN_INFO.find((doc) => doc.name === tokenName)?.token_address
        : NETWORK_INFO.find((doc) => doc.chain_id === Number(chainId))
            ?.tokenAddress;
    // console.log("tokenAddress", tokenAddress);

    const response = await web3Services.getp2pSecondTokenBalances(
      walletAddress,
      currentProvider,
      String(tokenAddress),
      tokenName
    );
    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * Withdrawal action
 * @param {*} currentProvider
 * @param {*} walletAddress
 * @param {*} amount
 * @param {*} coin
 * @param {*} chainId
 * @returns
 */
export const withdrawalFromContract = async (
  currentProvider,
  walletAddress,
  amount,
  coin,
  chainId
) => {
  try {
    const contrctaAddress = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    )?.contract_address;

    const response = await ApiServices.witdrawalRequest({
      address: walletAddress,
      chainId: chainId,
      symbol: coin?.symbol?.toLowerCase(),
      currency: coin?.currency_address,
      amount: amount,
      isCoin:
        coin?.currency_address === "0x0000000000000000000000000000000000000000"
          ? true
          : false,
      decimals: 18,
    });
    const { messageHash, signature } = response.data;

    if (messageHash && signature) {
      const res = await web3Services.withdrawalFromContract(
        currentProvider,
        contrctaAddress,
        walletAddress,
        messageHash,
        signature
      );
      if (res) {
        return true;
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getUSDTBalance = async (
  walletAddress,
  chainId,
  currentProvider
) => {
  try {
    const tokenAddress = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    )?.tokenAddress;
    const response = await web3Services.getUserUSDTBalance(
      tokenAddress,
      walletAddress
      // currentProvider,
    );
    console.log("service response", response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUSDTDecimals = async (currentProvider, chainId) => {
  try {
    const tokenAddress = NETWORK_INFO.find(
      (doc) => doc.chain_id === Number(chainId)
    )?.tokenAddress;
    console.log("token action", chainId, tokenAddress);
    const response = await web3Services.getUSDTDecimals(
      currentProvider,
      String(tokenAddress)
    );
    if (response) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const checkAllowance = async () => {
  try {
    const response = await web3Services.checkAllowance();
    console.log("allowance", response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendAllowance = async (depositAmount) => {
  try {
    const response = await web3Services.sendAllowance(depositAmount);
    return response;
  } catch (error) {
    throw error;
  }
};
