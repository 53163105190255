import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./BuySell.scss";
import {
  DoublearrowIcon,
  DownarrowIcon,
} from "../../../../assets/Svg/SvgImages";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Dropdown } from "react-bootstrap";
import MarketTab from "./MarketTab/MarketTab";
import LimitTab from "./LimitTab/LimitTab";
import indicatorinfo from "../../../../assets/images/indicator-info.png";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import PlaceOrder from "./PlaceOrder/PlaceOrder";
import DepositModal from "./DepositModal/DepositModal";
import WithdrawModal from "./WithdrawModal/WithdrawModal";
import AnalysisWidget from "../AnalysisWidget/AnalysisWidget";
import MoveFunds from "./MoveFunds/MoveFunds";
import ConnectWallet from "../../../Exchange/components/BuySell/ConnectWallet/ConnectWallet";

const BuySell = () => {
  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const marginMode = useAppSelector((state) => state.user.marginMode);

  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showMoveFunds, setShowMoveFunds] = useState(false);

  return (
    <div className="BuySell_V2 nondraggable_card">
      {!walletAddress && <ConnectWallet />}
      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>ASSETS</Accordion.Header>
          <Accordion.Body className="customTabs">
            <div className="BuySell_Assets">
              {/* <h5>
                Account <DoublearrowIcon />
              </h5> */}

              {/* <CommonButton
                // onClick={() => setShowMarginMode(true)}
                title={marginMode}
                className={`grey-border-btn ${showMarginMode && "active"}`}
              /> */}
              <CommonButton
                onClick={() => setShowWithdraw(true)}
                title="Withdraw"
                className={`grey-border-btn ${showWithdraw && "active"}`}
              />
              <CommonButton
                onClick={() => setShowDeposit(true)}
                title="Deposit"
                className={`grey-border-btn ${showDeposit && "active"}`}
              />
              <CommonButton
                onClick={() => setShowMoveFunds(true)}
                title="Move Funds"
                className={`grey-border-btn ${showMoveFunds && "active"}`}
              />
            </div>
            <DepositModal
              show={showDeposit}
              handleClose={() => setShowDeposit(false)}
            />
            <WithdrawModal
              show={showWithdraw}
              handleClose={() => setShowWithdraw(false)}
            />
            <MoveFunds
              show={showMoveFunds}
              handleClose={() => setShowMoveFunds(false)}
            />
          </Accordion.Body>
        </Accordion.Item>
        {walletAddress && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>ORDER</Accordion.Header>
            <Accordion.Body className="Order_Tabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="limit">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="" disabled>
                      {marginMode}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="market">Market</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="limit">Limit</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link>
                      <Dropdown className="Tab_dropdown">
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                          <span>Stop</span> <DownarrowIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item to="/">Limit</Dropdown.Item>
                          <Dropdown.Item to="/">Stop</Dropdown.Item>
                          <Dropdown.Item to="/">Limit</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="market">
                    <PlaceOrder type="M" />
                  </Tab.Pane>
                  <Tab.Pane eventKey="limit">
                    <PlaceOrder type="L" />
                  </Tab.Pane>
                  {/* <Tab.Pane>
                    <MarketTab />
                  </Tab.Pane> */}
                </Tab.Content>
              </Tab.Container>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {/* <Accordion.Item eventKey="2">
          <Accordion.Header>TECHNICAL ANALYSIS</Accordion.Header>
          <Accordion.Body>
            <div className="TechnicalAnalysis text-center">
              <h4 className="h4_heading">
                Technical Analysis for {selectedPairKey.split("_")[0]}
              </h4>
              <AnalysisWidget />
            </div>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </div>
  );
};

export default BuySell;
