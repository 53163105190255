import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Header } from "../../../common";
import { useDispatch } from "react-redux";
import "./Exchange.scss";
import "../../../common/Mobilenav/MobilenavStyle.scss";
import { saveFlashOrders } from "../redux/_slices/spotExchange.slice";
import ResponsiveExchange from "./ResponsiveLayout";
import { saveCurrentBrackpoint } from "../../../redux/_slices/layout.slice";
// import Workspace from "./Workspace/Workspace";
import { usePairListAPi } from "../hooks/hooks.service";
import {
  useSocketService,
  useSpotSocketService,
} from "../hooks/socket.hooks.service";
import MobileLayout from "./MobileLayout";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ExFooter from "../../../common/ExFooter/ExFooter";
import FavouriteTab from "./BuySell/FavouriteTab/FavouriteTab";

const SpotExchange = () => {
  let flashBarInterval = null;

  const dispatch = useDispatch();

  const lg = useMediaQuery({ query: "(min-width: 1480px)" });
  const md = useMediaQuery({ query: "(min-width: 991px)" });
  const sm = useMediaQuery({ query: "(min-width: 767px)" });
  const xs = useMediaQuery({ query: "(min-width: 320px)" });

  const current_breakpoint = useSelector(
    (state) => state.layout.current_breakpoint
  );

  const flashOrders = useSelector((state) => state.spotExchange?.flashOrders);
  const favPairList = useSelector((state) => state.user?.spotFavPairList);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const spotSocketHook = useSpotSocketService(splitLocation[1]);
  // Use Custume hooks
  usePairListAPi();
  // useSocketService(splitLocation[1]);

  const handleBreakpoint = (_lg, _md, _sm, _xs) => {
    if (!_lg && !_md && !_sm && _xs) {
      // console.log(" current barakpoint : xs");
      dispatch(saveCurrentBrackpoint("xs"));
    } else if (!_lg && !_md && _sm && _xs) {
      // console.log(" current barakpoint : sm");
      dispatch(saveCurrentBrackpoint("sm"));
    } else if (!_lg && _md && _sm && _xs) {
      // console.log(" current barakpoint : md");
      dispatch(saveCurrentBrackpoint("md"));
    } else if (_lg && _md && _sm && _xs) {
      // console.log(" current barakpoint : lg");
      dispatch(saveCurrentBrackpoint("lg"));
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (flashOrders?.length > 0) {
      flashBarInterval = setInterval(() => {
        if (flashOrders?.length === 0) {
          clearInterval(flashBarInterval);
          flashBarInterval = null;
        }
        dispatch(saveFlashOrders("sub", {}));
      }, 100);
    }

    return () => {
      clearInterval(flashBarInterval);
      flashBarInterval = null;
    };
  }, [flashOrders?.length]);

  useEffect(() => {
    handleBreakpoint(lg, md, sm, xs);
  }, [lg, md, sm, xs]);

  return (
    <>
      <div className="V2_Exchange_Layout">
        {/* <Workspace /> */}
        <div className="V2_Exchange p-0">
          <Header />
          <div className="V2_Exchange_Wrapper grid-stack">
            <div className="V2_Exchange_row">
              {favPairList.length > 0 && <FavouriteTab />}
              {current_breakpoint === "sm" || current_breakpoint === "xs" ? (
                <MobileLayout />
              ) : (
                <ResponsiveExchange />
              )}
            </div>
          </div>
          <ExFooter />
        </div>
      </div>
    </>
  );
};

export default SpotExchange;
