import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";

import { WagmiProvider, configureChains, createConfig } from "wagmi";
// import { bscTestnet, mainnet, bsc } from "wagmi/chains";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import animationLogo from "./assets/images/logo_icon.svg";
import { Provider } from "react-redux";
import store from "./redux/_store/configureStore";
import { PersistGate } from "redux-persist/lib/integration/react";
import persistStore from "redux-persist/es/persistStore";
import { BrowserRouter } from "react-router-dom";
import { REACT_APP_SITE_URL } from "./constants/constants";
import {
  holesky,
  mainnet,
  opBNBTestnet,
  polygonAmoy,
  sepolia,
  bsc,
  bscTestnet,
} from "viem/chains";
import { http } from "viem";

let persistor = persistStore(store);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr"],
    fallbackLng: "fr",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/langs/{{lng}}/translation.json",
    },
    react: { useSuspense: true },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

console.warn = () => {};

const metadata = {
  name: "Eterna Dex",
  description: "Eterna Dex",
  url: REACT_APP_SITE_URL,
  icons: [],
};

// @ts-expect-error 1. Get projectId
// const projectId = "c3b49c55d1b303325d4c139c24268550";
// if (!projectId) {
//   throw new Error("PROJECT_ID is not set");
// }

// const { chains, publicClient } = configureChains(
//   [bsc, mainnet],
//   [walletConnectProvider({ projectId }), publicProvider()]
// );

// // 3. Create modal
// createWeb3Modal({
//   wagmiConfig,
//   projectId,
//   themeMode: "dark",
//   // themeVariables: {
//   //   "--w3m-color-mix": "#00DCFF",
//   //   "--w3m-color-mix-strength": 20,
//   // },
//   defaultChain: chains[0],
// });
// redeploying
const loading = (
  <div className="Window_Loading">
    <img className="animLogo" src={animationLogo} />
  </div>
);
const queryClient = new QueryClient();
const config = createConfig({
  chains: [bscTestnet],
  multiInjectedProviderDiscovery: false,
  transports: {
    [mainnet.id]: http(),
  },
});

const cssOverrides = `
  .dynamic-footer.dynamic-footer__top-border {
    display: none;
    visibility: hidden;
  }
  .connect-button{
    background:#0935dc;
    border-color:#0935dc;
  }
  .connect-button .typography{
    color:#fff;
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback={loading}>
        <>
            <DynamicContextProvider
                settings={{
                    environmentId: "82c85dea-ff63-44fc-b35e-76e436c05f1d",
                    // environmentId: "2bb6f338-6a14-406b-9464-a6de28a58ea8",
                    walletConnectors: [EthereumWalletConnectors],
                    cssOverrides,
                }}
            >
                <WagmiProvider config={config}>
                    <QueryClientProvider client={queryClient}>
                        <DynamicWagmiConnector>
                            <Provider store={store}>
                                <PersistGate
                                    loading={null}
                                    persistor={persistor}
                                >
                                    <BrowserRouter>
                                        <App />
                                    </BrowserRouter>
                                </PersistGate>
                            </Provider>
                        </DynamicWagmiConnector>
                    </QueryClientProvider>
                </WagmiProvider>
            </DynamicContextProvider>
        </>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
