import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./TradingChart.scss";
import { dataFeedObject } from "./datafeed";
import { useSelector } from "react-redux";
import moment from "moment";
let previousStudies = 0;

const TradingChart = () => {
  // const [datafeed, setDatafeed] = useState()
  const [tradeData, setTradeData] = useState({});
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const selectedPair = useSelector((state) => state.user.selectedPair);
  const prevSelectedPairKey = useSelector(
    (state) => state.user.prevSelectedPairKey
  );
  const lastTrade = useSelector((state) => state.exchange.marketTrades);
  const symbol = selectedPairKey?.replace("_", "/")?.toUpperCase();
  const pricescaleValue = 10 ** selectedPair.price_scale;
  // const pricescaleValue = !isNaN(selectedPair.price_scale)
  //   ? 10 ** selectedPair.price_scale
  //   : 10 ** 2;
  const baseDecimal = 3;
  const priceDecimal = 3;
  const interval = "5";
  const theme = "dark";
  const tvWidget = useRef(null);

  const params = {
    // debug: true, // uncomment this line to see Library errors and warnings in the console
    // fullscreen: true,
    autosize: true,
    // symbol: 'BTC',
    interval: "5",
    container: "tv_chart_container",
    //	BEWARE: no trailing slash is expected in feed URL
    // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(datafeedUrl, undefined, {
    //   maxResponseLength: 1000,
    //   expectedOrder: 'latestFirst',
    // }),
    library_path: "/charting_library/",
    locale: "en",

    disabled_features: [
      "use_localstorage_for_settings",
      "header_symbol_search",
    ],
    enabled_features: ["study_templates"],
    charts_storage_url: "https://saveload.tradingview.com",
    charts_storage_api_version: "1.1",
    client_id: "tradingview.com",
    user_id: "public_user_id",
    theme: "Dark",
    overrides: {
      "paneProperties.background": "#080A12",
      "paneProperties.backgroundGradientStartColor": "#080A12",
      "paneProperties.backgroundGradientEndColor": "#080A12",
    },
    toolbar_bg: "#080A12",
    custom_css_url: "/charting_library/static/css/header.css",
    loading_screen: {
      backgroundColor: "#080A12",
    },
    enabled_features: ["seconds_resolution"],
  };

  const dataFeed = useRef(
    dataFeedObject(pricescaleValue, priceDecimal, baseDecimal, selectedPairKey)
  );

  const setChart = (selectedPairKey) => {
    dataFeed.current = dataFeedObject(
      pricescaleValue,
      priceDecimal,
      baseDecimal,
      selectedPairKey
    );
    const widgetOptions = {
      interval: interval,
      symbol: symbol ? symbol : "BTC/USDT",
      theme: theme,
      datafeed: dataFeed.current,
      ...params,
    };
    tvWidget.current = new window.TradingView.widget(widgetOptions);

    tvWidget.current.onChartReady(() => {
      const savedState = localStorage.getItem("tv_chart_state");
      if (savedState) {
        tvWidget.current.load(JSON.parse(savedState));
      }
      addEventListeners();
    });

    // Set an interval to periodically check for indicator chan
    window.frames[0].focus();
  };

  const updateChart = (selectedPairKey, priceDecimal, baseDecimal) => {
    tvWidget.current = null;
    dataFeed.current = dataFeedObject(
      pricescaleValue,
      priceDecimal,
      baseDecimal,
      selectedPairKey
    );
    const widgetOptions = {
      interval: interval,
      symbol: symbol ? symbol : "BTC/USDT",
      theme: theme,
      datafeed: dataFeed.current,
      ...params,
    };

    tvWidget.current = new window.TradingView.widget(widgetOptions);
    tvWidget.current.onChartReady(() => {
      const savedState = localStorage.getItem("tv_chart_state");
      if (savedState) {
        tvWidget.current.load(JSON.parse(savedState));
      }
      addEventListeners();
    });
    window.frames[0].focus();
  };

  const addEventListeners = async () => {
    if (tvWidget.current) {
      const chart = tvWidget.current.chart();

      // Listen for events that change the chart's state
      chart.onIntervalChanged().subscribe(null, saveChartState);
      chart.onSymbolChanged().subscribe(null, saveChartState);
      const fetchAndCompareStudies = () => {
        // console.log(previousStudies, "previousStudies");
        const currentStudies = chart.getAllStudies();
        // console.log(
        //   "currentStudies",
        //   currentStudies.length,
        //   "previousStudies",
        //   previousStudies
        // );
        if (previousStudies != currentStudies.length) {
          previousStudies = currentStudies.length;

          tvWidget.current.onChartReady(() => {
            saveChartState();
          });
        }
        previousStudies = currentStudies.length;

        setInterval(fetchAndCompareStudies, 3000); // Check every second

        // console.log('currentStudies', currentStudies);
      };
    }
  };

  const saveChartState = () => {
    if (tvWidget.current) {
      tvWidget.current.save((state) => {
        localStorage.setItem("tv_chart_state", JSON.stringify(state));
      });
    }
    // console.log("hello", tvWidget.current);
  };

  useEffect(() => {
    return () => {
      // saveChartState();
      // localStorage.deleteItem("tv_chart_state");
    };
    saveChartState();
  }, []);

  useLayoutEffect(() => {
    if (
      selectedPairKey &&
      (!prevSelectedPairKey || selectedPairKey !== prevSelectedPairKey)
    ) {
      // console.log("hi>>>>>>>>>>>>>>>>>", prevSelectedPairKey, tvWidget.current)
      if (prevSelectedPairKey && tvWidget.current) {
        // alert("if")
        updateChart(selectedPairKey, priceDecimal, baseDecimal);
      } else {
        // alert("else")
        setChart(selectedPairKey);
      }
    } else {
      // alert("hi elese")
      setChart(selectedPairKey);
    }
    // console.log("????????????????????????????", Object.keys(tradeData).length);

    window.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        console.log("browser tab is active");

        setChart(selectedPairKey);
      }
    });
  }, [selectedPairKey]);

  useEffect(() => {
    if (lastTrade.length > 0) {
      const tradeObject = {
        price: lastTrade.length > 0 ? Number(lastTrade[0].price) : 0,
        volume: lastTrade.length > 0 ? Number(lastTrade[0].filled_amount) : 0,
        time: Number(moment(Number(lastTrade[0].created_at)).unix()),
        open: Number(lastTrade[0].price),
        close: Number(lastTrade[0].price),
        high: Number(lastTrade[0].price),
        low: Number(lastTrade[0].price),
      };
      // console.log("tradeObject", tradeObject);
      // setTradeData(tradeObject);
      // console.log("lastTrade -> ", tradeObject);
      if (Object.keys(tradeObject).length > 0) {
        dataFeed.current.onRealtimeCallback(tradeObject);
      }
    }

    // initOnReady()
    // window.addEventListener('DOMContentLoaded', initOnReady, false);

    // return () => window.removeEventListener('DOMContentLoaded', initOnReady, false);
  }, [selectedPairKey, lastTrade]);

  return (
    <>
      <div className="tradingview-widget-container">
        <div id="tv_chart_container"></div>
      </div>
    </>
  );
};

export default memo(TradingChart);
