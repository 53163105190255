import React, { useEffect, useState } from "react";
import "./PlaceOrder.scss";
import WalletIcon from "../../../../../assets/images/bl_Wallet_icon.svg";

import SwitchButton from "../../../../../common/SwitchButton/SwitchButton";
import RangeSlider from "../../../../../common/RangeSlider/RangeSlider";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import greenArrow from "../../../../../assets/icons/greenarrow.svg";
import redArrow from "../../../../../assets/icons/redarrow.svg";
import { DownarrowIcon } from "../../../../../assets/Svg/SvgImages";
import OrderDetailsCard from "../OrderDetailsCard/OrderDetailsCard";
import MarginRatio from "../../MarginRatio/MarginRatio";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux.hooks";
import {
  amountValidationOnInput,
  showDecimal,
} from "../../../../../_utils/comman";
import {
  saveMakrTkrFee,
  saveMarketType,
  saveOrderPref,
  saveOrderPrice,
  saveOrderSize,
  saveOtherFee,
} from "../../../redux/_slices/spotExchange.slice";
import { useSelector } from "react-redux";
import {
  useTolalUsdBalance,
  useUserAllCoinBalances,
} from "../../../hooks/hooks.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { toastMsg } from "../../../../../common/Toast/Toast";
import { MARKET_TYPES } from "../../../../../constants/order.constant";
import { ApiServices } from "../../../services/api.services";
import {
  saveLeverage,
  saveUserLeverage,
} from "../../../../../redux/_slices/user.slice";
import OrderType from "./OrderType";
import LeverageSlider from "../../../../../common/LeverageSlider/LeverageSlider";

const PlaceOrder = (props) => {
  const triggerByMemo = [
    {
      value: "LastPrice",
      text: "Last Traded Price",
    },
    {
      value: "MarkPrice",
      text: "Mark Price",
    },
    {
      value: "IndexPrice",
      text: "Index Price",
    },
  ];

  const { type } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isActive, setActive] = useState(false);
  const [price, setPrice] = useState("");
  const [inputError, setInputError] = useState({
    price: false,
    size: false,
  });
  const [range, setRange] = useState(0);
  const [size, setSize] = useState("");
  const [checkOrder, setCheckOrder] = useState(false);
  const [checkedOne, setCheckedOne] = useState(true);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [levSlider, setLevSlider] = useState(true);
  const [profitAmount, setProfitAmount] = useState("");
  const [lossAmount, setLossAmount] = useState("");
  const [profitTriggerValue, setProfitTriggerValue] = useState(
    triggerByMemo[0]
  );
  const [lossTriggerValue, setLossTriggerValue] = useState(triggerByMemo[0]);

  const walletAddress = useAppSelector((state) => state.user.walletAddress);
  const orderSize = useAppSelector((state) => state.spotExchange.orderSize);
  const currentPair = useSelector((state) => state.user.currentPair);
  const selectedPairKey = useSelector((state) => state.user.selectedPairKey);
  const selectedPair = useSelector((state) => state.user.selectedPair);
  const activeCoins = useSelector((state) => state.spotExchange.activeCoins);
  const chainId = useSelector((state) => state.user.chainId);
  const orderPrice = useSelector((state) => state.spotExchange.orderPrice);
  const marketType = useSelector((state) => state.spotExchange.marketType);
  const userPaymentCurrency = useSelector(
    (state) => state.spotExchange.userPaymentCurrency
  );
  const currentLtp = useSelector(
    (state) => state.spotExchange.orderBook.currentLtp
  );
  const orderPrefDropdown = useSelector(
    (state) => state.spotExchange.orderPref
  );
  // const leverage = useSelector((state) => state.user.leverage);
  // const leverageBar = useAppSelector((state) => state.user.leverageInfo);
  // const userLeverage = useAppSelector((state) => state.user.userLeveragee);
  // const leverageInfo = useAppSelector(
  //   (state) => state.spotExchange.minMaxLeverage
  // );
  const currentPrice = useAppSelector(
    (state) => state?.spotExchange?.currentSpotPrice
  );
  const walletBalance = useAppSelector(
    (state) => state.user.userWalletBalances
  );
  const coinArray = selectedPairKey.split("_");
  coinArray[0] = coinArray[0]?.replace("USDT", "");
  coinArray[1] = "USDT";
  const [selectedOption, setSelectedOption] = useState(coinArray[0]);
  let decimalLimitAmount = currentPair?.min_order_qty.toString().includes(".")
    ? currentPair?.min_order_qty.toString().split(".")
    : "";
  const { userBalance, getBalances } = useUserAllCoinBalances();
  // const [minMaxLeverage, setMinMaxLeverage] = useState(leverageInfo);
  const [selectedCoinOption, setSelectedCoinOption] = useState(null);
  const { totalUsdBalance } = useTolalUsdBalance("single");

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    dispatch(saveMarketType(type));
    if (type === "M") {
      dispatch(saveOrderPrice(currentLtp));
    }
  }, [type]);

  // useEffect(() => {
  //   setMinMaxLeverage(leverageInfo);
  // }, [leverageInfo]);

  const getEternaFee = async () => {
    let data = {
      side: "",
    };
    const response = await ApiServices.mkrTkrFee();
    if (response && response.status === 200) {
      dispatch(saveMakrTkrFee(response?.data));
    } else if (response) {
      toastMsg.error(response.message, { toastId: "errormsg" });
    }

    const responseOtherFee = await ApiServices.otherFee();
    if (responseOtherFee && responseOtherFee.status === 200) {
      dispatch(saveOtherFee(responseOtherFee?.data));
    } else if (responseOtherFee) {
      toastMsg.error(response.message, { toastId: "errormsg" });
    }
  };

  // useEffect(() => {
  //   dispatch(saveUserLeverage(leverageBar?.leverage));
  // }, [leverageBar]);

  useEffect(() => {
    getEternaFee();
  }, []);

  const handleOnChange = (event, type) => {
    if (type == "last") {
      setPrice(event);
      setInputError({ ...inputError, price: false });
      dispatch(saveOrderPrice(event));
    } else {
      const { name, value } = event.target;

      let valData;

      if (
        decimalLimitAmount.length > 0 &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        valData = showDecimal(value, decimalLimitAmount[1].length);
      } else if (
        value?.toString().includes(".") &&
        name == "size" &&
        selectedOption != "USDT"
      ) {
        return;
      } else {
        // console.log("selectedPair", selectedPair?.price_scale, value);
        if (selectedOption == "USDT") {
          valData = showDecimal(value, 2);
        } else {
          valData = showDecimal(value, selectedPair?.price_scale);
        }
      }

      if (name === "price") {
        valData = showDecimal(value, selectedPair?.price_scale);
        setPrice(valData);
        setInputError({ ...inputError, price: false });
        dispatch(saveOrderPrice(valData));
      } else {
        setInputError({ ...inputError, size: false });
        setSize(valData);
        dispatch(saveOrderSize(valData));
      }
    }
  };

  useEffect(() => {
    if (currentPrice) {
      setPrice(currentPrice);
      dispatch(saveOrderPrice(currentPrice));
    }
  }, [currentPrice]);

  // Handle order validation
  const orderValidation = () => {
    console.log("validationnnnnnnnnnnnn", orderPrice, orderSize);
    if (orderPrice && Number(orderPrice) <= 0) {
      setInputError({ size: false, price: true });
      return true;
    }
    if (orderSize && Number(orderSize) <= 0) {
      setInputError({ price: false, size: true });
      return true;
    }
    setInputError({ price: false, size: false });
    return false;
  };

  const handleKeyPress = (e) => {
    // setRangeSlider();
    //don't allow decimal in qty if min_order_qty is whole number
    if (
      selectedOption != "USDT" &&
      Number.isInteger(currentPair?.min_order_qty)
    ) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  // Create order Buy or Sell
  const handleCreateOrder = async (side) => {
    console.log(">>>>>>>>>>>handle create order", side);
    // if (totalUsdBalance <= 0) {
    //   toast.error(t("comman_word.AddFundstoAccount"), {
    //     toastId: "errorfunds",
    //   });
    //   return;
    // }
    try {
      const paymentSymbol = activeCoins.find(
        (doc) =>
          doc.chain_id === Number(chainId) &&
          doc.currency_address?.toLowerCase() ===
            userPaymentCurrency?.toLowerCase()
      )?.symbol;
      if (!orderValidation()) {
        console.log(
          ">>>>>>>>>>>>>>>>>",
          orderPrefDropdown,
          orderSize,
          orderPrice,
          selectedPair.min_order_qty
        );
        setCheckOrder(true);
        let qty;
        if (orderPrefDropdown === "USDT") {
          qty = Number(orderSize);
        } else {
          qty = Number(orderSize) * Number(orderPrice);
        }
        // if (side === "Buy") {
        //   qty = Number(orderSize) * Number(orderPrice);
        // } else {
        //   qty = Number(orderSize);
        // }

        console.log(">>>>>>>>>>>>>>>>>>>>>>>", qty);
        if (
          orderPrefDropdown === "USDT" &&
          qty < selectedPair.min_order_amount
        ) {
          toastMsg.error(
            `Minimum order quantity must be greater than ${selectedPair.min_order_amount}`,
            { toastId: "errormsg" }
          );
          setCheckOrder(false);
          return;
        } else if (
          orderPrefDropdown !== "USDT" &&
          qty < selectedPair.min_order_qty
        ) {
          toastMsg.error(
            `Minimum order quantity must be greater than ${selectedPair.min_order_qty}`,
            { toastId: "errormsg" }
          );
          setCheckOrder(false);
          return;
        }
        const data = {
          symbol: selectedPairKey,
          side,
          order_type: type === "L" ? "Limit" : "Market",
          price: type === "L" ? Number(orderPrice) : Number(currentLtp),
          // leverage: leverage,
          qty: qty,
          address: walletAddress,
          // takeProfit: checkedOne ? profitAmount : "",
          // stopLoss: checkedOne ? lossAmount : "",
          // slTriggerBy: checkedOne ? lossTriggerValue.value : "",
          // tpTriggerBy: checkedOne ? profitTriggerValue.value : "",
        };

        //set leverage
        // const dataObj = {
        //   address: walletAddress,
        //   leverage: leverage?.toString(),
        //   symbol: selectedPairKey,
        // };
        // dispatch(saveOrderSize(""));
        // setRange(0);
        // console.log(
        //   "leverage values>>>>>>>>",
        //   Number(leverage),
        //   Number(userLeverage)
        // );
        // if (Number(leverage) !== Number(userLeverage)) {
        //   const leverageResp = await ApiServices.setLeverage(dataObj);
        //   if (leverageResp && leverageResp.status === 200) {
        //     dispatch(saveUserLeverage(dataObj.leverage));
        //   }
        // }

        // if (leverageResp && leverageResp.status === 200) {
        const response = await ApiServices.createOrder(data);
        if (response && response.status === 200) {
          setCheckOrder(false);
          toastMsg.success("Order Created Successfully.", {
            toastId: "successMsg",
          });
          getBalances();
          setProfitAmount("");
          setLossAmount("");
        } else {
          setCheckOrder(false);
          toastMsg.error(response.message, { toastId: "errormsg" });
        }
        // }
      } else {
        console.log("nnnnnnnnnnnnnnnnnnnnnnn");
      }
    } catch (error) {
      console.log(">>>>>>>>>>>>>>>>>>>>>>error", error);
      toastMsg.error(error?.data?.message, { toastId: "errormsg" });
      setCheckOrder(false);
      dispatch(saveOrderSize(""));
      setRange(0);
    }
  };

  // const handleLeverageSwitch = (e) => {
  //   setLevSlider(e.target.checked);
  // };

  // const handleSetLeverage = (value) => {
  //   setRange(value);
  //   dispatch(saveLeverage(value));
  // };

  useEffect(() => {
    dispatch(saveOrderPref("USDT"));
    setSelectedOption("USDT");
    dispatch(saveOrderPrice(currentLtp));
  }, [coinArray[0]]);

  const handleSetSelectedOption = (value) => {
    setSelectedOption(value);
    dispatch(saveOrderPref(value));
  };

  return (
    <>
      <div className="Market_Tab">
        <div className={isActive ? "Advanced_Long openbtns" : "Advanced_Long"}>
          <div className="Advanced_btns Custom_Order_Tab">
            <CommonButton
              title="BUY"
              // icon={greenArrow}
              className="logn-btn w-50 me-1 hideIcon"
              btnicon="icon_block"
              onClick={() => {
                handleCreateOrder("Buy");
              }}
            />
            <CommonButton
              title="SELL"
              // icon={redArrow}
              className="short-btn w-50 ms-1 hideIcon"
              btnicon="icon_block"
              onClick={() => {
                handleCreateOrder("Sell");
              }}
            />
          </div>
        </div>

        <div className="Available_balance d-flex justify-content-between">
          <h5>Trading Balance</h5>
          <p>
            <img src={WalletIcon} alt="icon" />{" "}
            {walletBalance?.derivativeWalletBalance} <span>USDT</span>
          </p>
        </div>

        {/* ////////////////////////////////////////////////// */}

        <OrderType
          eventKey={type}
          walletAddress={walletAddress}
          totalUsdBalance={walletBalance}
          //  handleCalculatorModal={(value) =>
          //    calculatorModalRef.current.handleModal(value)
          //  }
          setSelectedCoinOption={setSelectedCoinOption}
          orderPrice={orderPrice}
          fieldError={inputError}
          currentPair={currentPair}
          selectedPair={selectedPair}
          coinArray={coinArray}
          handleSetSelectedOption={handleSetSelectedOption}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setRange={setRange}
          range={range}
          //  resetTkSl={resetTkSl}
        />

        {/* ////////////////////////////////////////////// */}
        {/* <div className="Leverage_Slider">
          <div className="d-flex align-items-center">
            <h5 className="common_heading">Leverage</h5>
            <SwitchButton
              title="Slider"
              handleSwitchChange={handleLeverageSwitch}
              checked={levSlider}
            />
          </div>
          {levSlider && (
            <LeverageSlider
              min={minMaxLeverage?.min_leverage || 0}
              max={minMaxLeverage?.max_leverage || 1}
              format="x"
              handleSetRange={handleSetLeverage}
              rangeData={range}
            />
          )}
        </div> */}

        <div className={isActive ? "Advanced_Long openbtns" : "Advanced_Long"}>
          <h5 className="common_heading" onClick={toggleClass}>
            Advanced <DownarrowIcon />
          </h5>
        </div>

        {/* <OrderDetailsCard /> */}

        {/* <CommonButton
          title={
            walletAddress
              ? `PLACE ${type.toUpperCase()} ORDER`
              : "CONNNECT TO TRADE"
          }
          className="blue-gradient-btn w-100"
        /> */}

        <MarginRatio />
      </div>
    </>
  );
};

export default PlaceOrder;
