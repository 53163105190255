import React from "react";
import "./ConnectWallet.scss";
import CommonButton from "../../../../../common/CommonButton/CommonButton";
import WallectIcon from "../../../../../assets/icons/wallet_icon.svg";
import { DynamicWidget } from "@dynamic-labs/sdk-react-core";
// import { useWeb3Modal } from "@web3modal/wagmi/react";

const ConnectWallet = () => {
  // const { open } = useWeb3Modal();

  return (
    <div className="connectWalletOverlay">
      <DynamicWidget innerButtonComponent={<h6>Connect Wallet</h6>} />

      {/* <CommonButton
        className={"connect_wlt"}
        icon={WallectIcon}
        btnicon="icon_block"
        title={"Connect Wallet"}
        // onClick={() => open()}
      /> */}
    </div>
  );
};

export default ConnectWallet;
